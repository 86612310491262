.ButtonWrapper {
    width: 240px;

    @media only screen and (max-width: 768px) {
        width: 100%;
    }
}

.Form {
    width: 480px;

    @media only screen and (max-width: 768px) {
        width: 100%;
    }
}