* {
  padding: 0;
  margin: 0;
}

body {
  *,
  ::before,
  ::after {
    border-width: 0;
    border-style: solid;
    border-color: #f2f4f4;
  }

  hr {
    border-style: solid;
    border-top-width: 2px;
  }

  margin: 0;
  min-height: 100vh;
  padding: 0;
  position: relative;
  font-family: 'Encode Sans Semi Expanded Normal';
  background: white;
  color: #2d3133;
  overflow-y: scroll;
  overflow-x: hidden;
}

a {
  cursor: pointer;
}

.form-select__indicator {
  padding: 0 !important;
}

.form-select__value-container {
  width: fit-content;
}

.react-calendar {
  border: none !important;
  width: auto !important;
  max-width: 450px !important;
}

.react-calendar__tile {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: space-between !important;
  height: 52px;
}

.react-calendar__tile--active {
  background-color: #e6e6e6 !important;
  color: black !important;
}

.react-calendar__tile:hover {
  background-color: #f2f4f4 !important;
}

.react-calendar__tile--now:not(.react-calendar__tile--active) {
  background-color: #f2f4f4 !important;
}

.react-calendar__month-view__weekdays__weekday {
  abbr[title] {
    text-decoration: none !important;
  }
}

.react-calendar__month-view__days__day--neighboringMonth {
  visibility: hidden;
}

// .form-select__menu {
//   // height: 70% !important;
//   background-color: white !important;
// }
