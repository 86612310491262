g.recharts-layer.recharts-brush-traveller {
  display: none;
}

g.recharts-cartesian-axis-ticks {
  font-size: 10px;
  color: #c5c9cb;
}

.recharts-cartesian-axis-tick-line {
  display: none;
}

.recharts-layer.recharts-cartesian-axis.recharts-xAxis.xAxis {
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.recharts-tooltip-wrapper {
  font-size: 10px;
}

.recharts-tooltip-item-list li {
  padding: 0;
  margin: 0;
}

.recharts-layer.recharts-cartesian-axis.recharts-yAxis.yAxis {
  position: sticky;
  left: 0;
}

// .recharts-wrapper {
//   @media only screen and (max-width: 768px) {
//     height: 185px !important;
//   }
// }

.recharts-wrapper::-webkit-scrollbar {
  -webkit-appearance: none;
}

.recharts-wrapper::-webkit-scrollbar:vertical {
  width: 11px;
}

.recharts-wrapper::-webkit-scrollbar:horizontal {
  height: 8px;
  background-color: #f2f4f4;
  border-radius: 10px;

  @media only screen and (max-width: 768px) {
    height: 16px;
    position: absolute;
    top: 30px;
  }
}

.recharts-wrapper::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #c5c9cb;
}

.YearChart {
  @media only screen and (max-width: 768px) {
    overflow-x: scroll;
    overflow-y: hidden;
    height: 185px !important;
  }
}

.YearChart::-webkit-scrollbar {
  -webkit-appearance: none;
}

.YearChart::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #c5c9cb;
}

.YearChart::-webkit-scrollbar:vertical {
  width: 11px;
}

.YearChart::-webkit-scrollbar:horizontal {
  @media only screen and (max-width: 768px) {
    height: 16px;
    background-color: #f2f4f4;
    border-radius: 10px;
    position: absolute;
    top: 30px;
  }
}

.YearResponsiveContainer {
  margin-left: -5px;
  @media only screen and (max-width: 768px) {
    min-width: 950px;
  }
}
