.CheckboxWrapper::-webkit-scrollbar {
  -webkit-appearance: none;
}

.CheckboxWrapper::-webkit-scrollbar:vertical {
  width: 4px;
  background-color: #F2F4F4;
  border-radius: 10px;
  margin: 10px 0 ;

  @media only screen and (max-width: 768px) {
    position: absolute;
    top: 30px;
  }
}

.CheckboxWrapper::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #C5C9CB;
}